





































































import BotGuardHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/BotGuardHandlerModule/BotGuardHandlerModuleBuilder";
import { InputSetups } from "@/mixins/input-setups";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import GoBackButton from "@/components/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from "@/components/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";

import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SaveModuleButton,
    GoBackButton,
    SwitchInput,
    NestedContent
  }
})
export default class BotGuardHandlerModuleView extends Mixins(InputSetups, ModuleBindings) {
  @VModel() module!: BotGuardHandlerModuleBuilder
}
