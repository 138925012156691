var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bot-guard-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$t(_vm.module.model.descriptionKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'facecontrol_username_remove_bots',
          'hasAnchor': true,
        }
      }}}),(_vm.module.model.params.facecontrol_username_remove_bots)?_c('nested-content',{staticClass:"mb-0"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'facecontrol_username_ban_bot_add_user',
            'hasAnchor': true,
          }
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'journal_facecontrol',
          'prefix': 'bot_guard_handler_module_',
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }